.container {
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
  background-color: #fff7ed;
  align-items: center;
  justify-content: center;
}

.contentContainer {
  width: 327px;

  :global(.ant-form-item-label) {
    padding-bottom: 4px !important;
    label {
      font-size: 16px;
    }
  }
}

.title {
  width: 100%;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  font-family: 'StabilGrotesk-Bold2';
  color: #292e62;
  margin-bottom: 36px;
  display: inline-block;
}

.hightlight {
  font-weight: normal;
  font-style: italic;
  font-family: 'Tiempos Headline', sans-serif;
}

.forgotPassword {
  cursor: pointer;
  font-family: 'StabilGrotesk-Bold2';
  color: #292e62;
  text-decoration: underline;
  font-size: 14px;
  display: inline-block;
  margin-bottom: 32px;
}
