.button {
  background: #292e62;
  color: #fff7ed;
  border-radius: 32px;
  min-height: 40px;
  padding: 10px 24px;
  width: 100%;
  font-weight: 400;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  text-align: center;

  &:disabled {
    &:not(.tertiary) {
      background: #bfbeca;
    }
    &.tertiary {
      color: #727272;
    }
    color: #fff7ed;
    border: 0;
    cursor: not-allowed;
  }

  &:active:not(:disabled) {
    transform: scale(0.99);
  }

  flex-basis: 113px;
  white-space: nowrap;
}

.secondary {
  background: transparent;
  color: #292e62;
  flex-shrink: 3;

  border: 1px solid #a9a7b5;
}

.tertiary {
  flex-shrink: 3;
  background: transparent;
  color: #292e62;
}

.block {
  flex-basis: 100%;
  max-width: none;
  display: block;
  width: 100%;
}
