.switcher {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 1.5rem;
}

.button {
  background: transparent;
  color: #292e62;
  text-transform: uppercase;
  padding: 0;
  transition: color 0.3s;

  &:hover {
    color: #a9a7b5;
  }
}

.buttonActive {
  font-weight: bold;
  text-decoration: underline;

  &:hover {
    color: #292e62;
  }
}
