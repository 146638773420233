@font-face {
  font-family: 'StabilGrotesk-Bold';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('StabilGrotesk-Bold'),
    url(../fonts/StabilGrotesk-Bold.woff) format('woff');
}

@font-face {
  font-family: 'StabilGrotesk-Bold2';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('StabilGrotesk-Bold2'),
    url(../fonts/StabilGrotesk-Bold.woff2) format('woff2');
}

@font-face {
  font-family: 'StabilGrotesk-Regular';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('StabilGrotesk-Regular'),
    url(../fonts/StabilGrotesk-Regular.woff) format('woff');
}

@font-face {
  font-family: 'StabilGrotesk-Regular2';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('StabilGrotesk-Regular2'),
    url(../fonts/StabilGrotesk-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Tiempos Headline';
  src: url(../fonts/TiemposHeadline-RegularItalic.woff2) format('woff2'),
    url(../fonts/TiemposHeadline-RegularItalic.woff) format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Tiempos Headline';
  src: url(../fonts/TiemposHeadline-LightItalic.woff2) format('woff2'),
    url(../fonts/TiemposHeadline-LightItalic.woff) format('woff');
  font-weight: light;
  font-style: italic;
  font-display: fallback;
}
.font-default {
  font-family: StabilGrotesk-Regular, sans-serif;
}

.font-bold {
  font-family: StabilGrotesk-Bold, sans-serif;
}

* {
  font-family: StabilGrotesk-Regular, sans-serif;
}
