@import './styles/fonts.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

svg {
  vertical-align: baseline;
}

html,
body,
#root {
  height: 100%;
}

.app-content {
  background: #fff7ed;
  margin: 0;
  overflow: hidden;
  position: relative;
}

.app-content-child {
  overflow: auto;
  height: 100%;
}

.app-content-child > div:first-child {
  height: 100%;
}

.app-content .ant-modal-mask,
.app-content .ant-modal-wrap {
  position: absolute !important;
}

.ant-dropdown-menu-item i {
  margin-right: 10px;
}

/* Override antd form item style */
.ant-form-item {
  margin-bottom: 10px !important;
  row-gap: 10px !important;
}

.ant-table-placeholder td {
  border-bottom: none !important;
}

.ant-layout-header {
  background: #293662 !important;
}

.ant-dropdown-menu-title-content {
  display: flex;
  align-items: center;
  gap: 5px;
}

.antd-field-required:before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.ant-form-item-label > label {
  white-space: normal;
  height: auto !important;
  min-height: 32px;
}

.required:before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

/*
    GeneralFields styles
*/

.rex-combo .ant-select .ant-select-selector > .ant-select-selection-item,
.rex-datepicker .ant-picker-input > input {
  width: 2px !important;
  /*max-width: 150px !important;*/
}
.ant-form-item-control {
  flex: 1 !important;
}

.full-width-modal .ant-modal-body {
  padding: 0;
}

/*
  --- //GeneralFields styles
*/

.ant-menu-inline-collapsed-tooltip
  .ant-tooltip-arrow
  .ant-tooltip-arrow-content {
  --antd-arrow-background-color: linear-gradient(
    to right bottom,
    #fff7ed,
    #fff7ed
  );
}

.ant-menu-inline-collapsed-tooltip .ant-tooltip-inner svg {
  display: none;
}

.ant-menu-inline-collapsed-tooltip .ant-tooltip-inner,
.ant-menu-submenu-popup > ul {
  background-color: #fff7ed !important;
}
.ant-menu-inline-collapsed-tooltip .ant-tooltip-inner a span,
.ant-menu-submenu-popup > ul li span {
  color: #292e62 !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.ant-menu-submenu-popup .ant-menu-item-selected {
  background-color: #ffdfbc !important;
}

.ant-form-vertical .ant-form-item .ant-form-item-label > label {
  display: block;
  min-height: auto;
}

.ant-form-item .ant-form-item-label > label {
  color: #292e62;
  font-family: 'StabilGrotesk-Bold';
}

.ant-form-item
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 8px;
}

.ant-form-item .ant-input {
  border-radius: 8px;
}

.ant-form-item .ant-picker {
  border-radius: 8px;
  display: flex;
}

.ant-form-item .ant-input-affix-wrapper {
  border-radius: 8px;
  overflow: hidden;
}

.ant-form.ant-form-vertical .ant-form-item {
  margin-bottom: 20px !important;
}

.ant-collapse {
  border-radius: 8px !important;
  background-color: #fff !important;
}

.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header,
.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 8px 8px !important;
}

.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  font-size: 16px;
}

.ant-radio-button-wrapper:first-child:last-child {
  border-radius: 32px !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: #fff7ed !important;
  color: #292e62 !important;
  border-color: #292e62 !important;
}

.ant-radio-button-wrapper {
}
