.header {
  color: white;
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 1000;
  width: 100vw;
  left: 0;
  top: 0;
  background-color: #fff7ed !important;
  padding: 0 24px !important;
  padding-right: 32px !important;
  box-shadow: 0px 3px 18px rgba(51, 51, 51, 0.08);
}

.routes {
  margin: 0 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;

  > .route,
  > div,
  > div > .route {
    height: 100%;
  }

  > .route,
  > div > .route {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > .route[is-active],
  > div > .route[is-active] {
    border-top: solid 4px transparent;
    border-bottom: solid 4px #292e62;
  }
}

.route {
  width: 120px;
  padding: 0 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #292e62;
  &:hover {
    color: #a9a7b5;
  }
  text-align: center;
}

.childRoutes,
.userPanelTooltip {
  top: 50px !important;
}

.childrenRoutesList {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.clinicSelect {
  width: 172px !important;
  > div {
    background-color: transparent !important;
    border-radius: 4px !important;
    color: #292e62 !important;
  }

  :global(.ant-select-selector) {
    height: 32px !important;
    font-size: 16px;
    color: #292e62;
    line-height: 24px;
    span {
      margin-top: 4px;
    }
  }
}

.userPanelWrapper {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #292e62;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 16px;
}

.userPanelTooltip {
  min-width: 120px;
}

.rightContent {
  display: flex;
  align-items: center;
  margin-left: auto;
  column-gap: 32px;
}

.userPanelContent {
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.logoSmall {
  display: none;
}

@media (max-width: 1100px) {
  .logoSmall {
    display: unset;
  }

  .logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo {
    display: none;
  }

  .routes {
    margin: 0 10px;
  }

  .route {
    width: 90px;
    padding: 0 5px;
  }

  .route {
    font-size: 14px;
  }

  .pinModalTriggerContainer {
    font-size: 14px !important;
  }

  .rightContent {
    column-gap: 16px;
  }
}

.pinModalTriggerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #292e62;
  height: 20px;
  font-size: 18px;
  svg {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }

  span {
    display: inline-block;
    white-space: nowrap;
    font-family: 'StabilGrotesk-Bold2';
  }
}
