.input {
  height: 60px;
  width: 100% !important;
  outline: none;
  border-radius: 8px;
  border: 1px solid #bfbeca;
  background-color: transparent;
  font-size: 26px;
  color: #292e62;
  font-weight: 700;
}

.container {
  display: flex;
  > div {
    margin-right: 8px;
    flex: 1;
    width: 100%;
  }

  > div:last-of-type {
    margin-right: 0;
  }
}

.invalidCode {
  border-color: #ff4d4f;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.error {
  margin-top: 4px;
  margin-right: auto;
  color: #ff4d4f;
  font-size: 14px;
  line-height: 14px;
}
