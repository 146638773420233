.container {
  padding-bottom: 0;
  max-width: 375px;
  & > div:nth-of-type(2) {
    border-radius: 16px;

    & > div {
      padding: 0;
    }
  }
}

.contentContainer {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: flex-start;
  column-gap: 24px;
  background-color: #fffbf6;
  padding: 0 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 8px;
}

.topIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cancelIconContainer {
  padding: 0;
  margin: 0;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 24px;
  top: 16px;
}

.title {
  width: 100%;
  font-family: 'StabilGrotesk-Bold2';
  color: #292e62;
  margin-bottom: 8px;
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  padding-right: 24px;
}

.description {
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
  margin-bottom: 8px;
  color: #727272;
}

.hightlight {
  font-weight: normal;
  font-style: italic;
  font-family: 'Tiempos Headline', sans-serif;
}

.innerContent {
  display: flex;
  flex-direction: column;
}

.controls {
  display: flex;
  margin-left: auto;
}

.controlsOnDiffLines {
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
  height: 80px;
  margin-left: -20px;
  margin-bottom: 10px;
}

.controlsOnDiffLinesWithThird {
  height: 130px;
}
