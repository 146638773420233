.container {
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  row-gap: 2px;
}

.criteria {
  display: flex;
  flex-direction: row;
  color: #292e62;

  font-size: 14px;
  line-height: 16px;
  &[is-valid='true'] {
    .iconContainer {
      color: #292e62;
    }
  }

  .iconContainer {
    margin-right: 6px;
    color: #ff4d4f;
  }
}
