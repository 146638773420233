.appMenu {
  padding-top: 4px !important;

  background-color: #fff7ed !important;
  ul {
    background-color: #fff7ed !important;
  }

  span {
    color: #292e62 !important;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  :global(.ant-menu-submenu-open > div > .ant-menu-submenu-expand-icon > svg) {
    transform: rotate(180deg);
  }

  :global(.ant-menu-item),
  :global(.ant-menu-submenu) {
    border-radius: 8px;
    margin: 8px !important;
    width: calc(100% - 16px) !important;
    padding-left: 16px !important;

    :global(.anticon) {
      margin-bottom: -2px;
    }
  }

  :global(.ant-menu-submenu ul) {
    padding-left: 6px !important;
  }

  :global(.ant-menu-submenu > div) {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding: 0 !important;
  }

  :global(.ant-menu-sub) {
    padding-left: 24px !important;
  }

  :global(.ant-menu-item-selected) {
    background-color: #ffdfbc !important;
  }
}

.sider {
  border-right: 1px solid #d4d2d8;
  overflow: auto;
  background-color: #fff7ed !important;
  overflow: visible;
}

.menuControl {
  position: absolute;
  right: -22px;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  width: 44px;
  height: 44px;
  background-color: #fff7ed;
  border-radius: 50%;
  box-shadow: 0px 1px 4px rgba(0, 24, 43, 0.17);
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-top: 4px;
  }
}

.activeSubMenu {
  background-color: #ffdfbc !important;
  border-radius: 8px;
}
