.container {
  width: 100%;
  height: 100%;
  background-color: #fff7ed;
  display: flex;
  align-items: flex-start;
  padding-top: 80px;
  justify-content: center;
  text-align: center;
}

.innerContainer {
  width: 480px;
}

.title {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  font-family: 'StabilGrotesk-Bold2';
  color: #292e62;
  margin-bottom: 8px;
}

.subtitle {
  color: #727272;
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
  margin-bottom: 20px;
}

.hightlight {
  font-weight: normal;
  font-style: italic;
  font-family: 'Tiempos Headline', sans-serif;
}

.form {
  margin-bottom: 40px !important;
  :global(.ant-form-item-explain-error) {
    text-align: start;
  }
}

.pinCodeContainer {
  & > div:first-of-type {
    width: 216px;
  }

  display: flex;
  column-gap: 16px;
  & > div > div input {
    height: 48px !important;
    background-color: #fff !important;
  }
}

.pinCodeVisibilityToggle {
  height: 48px;
  svg {
    width: 24px;
    height: 24px;
    fill: rgba(0, 0, 0, 0.45);
  }
}

.controls {
  display: flex;
  align-items: center;
  column-gap: 30px;
  & > button {
    flex: 1;
  }
}
