.alert {
  position: fixed;
  z-index: 1000;
  display: flex;
  max-height: 100vh;

  padding: 32px 24px;

  align-items: center;
  left: 0;
  right: 0;

  @media (min-width: 640px) {
    align-items: flex-end;
    left: auto;
    right: auto;
  }

  &:global.alert-top,
  &:global.alert-topLeft,
  &:global.alert-topRight {
    top: 0;
    flex-direction: column;
  }

  &:global.alert-topRight {
    @media (min-width: 640px) {
      right: 0;
    }
  }

  :global {
    .alert-notice {
      min-width: 200px;
      position: relative;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      width: auto;
      margin: 10px 0;
      line-height: 1.5;
      background: #ffdfbc;
      border: 2px solid #fe8188;
      border-radius: 8px;
      color: #727272;

      @media (min-width: 640px) {
        max-width: 300px;
      }

      &:before {
        content: '';
        background: url('./assets/error.svg') no-repeat 50% 50%;
        display: block;
        width: 40px;
        height: 20px;
        flex: none;
      }

      // Content
      &-content {
        padding: 10px 0;
      }

      &-close {
        flex: none;
        margin-left: auto;
        display: block;
        outline: none;
        cursor: pointer;
        font-size: 0;
        width: 30px;
        height: 30px;
        background: url('./assets/close.svg') no-repeat 50% 50%;
      }
    }

    .alert-fade {
      overflow: hidden;
      transition: all 0.3s;
    }

    .alert-fade-appear-start {
      opacity: 0;
      transform: translateY(-100%);

      @media (min-width: 640px) {
        transform: translateX(100%);
      }
    }

    .alert-fade-appear-active {
      opacity: 1;
      transform: translateY(0);

      @media (min-width: 640px) {
        transform: translateX(0);
      }
    }
  }
}

.alert-success {
  position: fixed;
  z-index: 1000;
  display: flex;
  max-height: 100vh;

  padding: 32px 24px;

  align-items: center;
  left: 0;
  right: 0;

  @media (min-width: 640px) {
    align-items: flex-end;
    left: auto;
    right: auto;
  }

  &:global.alert-success-top,
  &:global.alert-success-topLeft,
  &:global.alert-success-topRight {
    top: 0;
    flex-direction: column;
  }

  &:global.alert-success-topRight {
    @media (min-width: 640px) {
      right: 0;
    }
  }

  :global {
    .alert-success-notice {
      min-width: 200px;
      position: relative;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      width: auto;
      margin: 10px 0;
      line-height: 1.5;
      background: #292e62;
      border: 2px solid #292e62;
      border-radius: 8px;
      color: #fff7ed;

      @media (min-width: 640px) {
        max-width: 300px;
      }

      &:before {
        content: '';
        background: url('./assets/success.svg') no-repeat 50% 50%;
        display: block;
        width: 40px;
        height: 20px;
        flex: none;
      }

      // Content
      &-content {
        padding: 10px 0;
      }

      &-close {
        flex: none;
        margin-left: auto;
        display: block;
        outline: none;
        cursor: pointer;
        font-size: 0;
        width: 30px;
        height: 30px;
        background: url('./assets/closev2.svg') no-repeat 50% 50%;
      }
    }

    .alert-fade {
      overflow: hidden;
      transition: all 0.3s;
    }

    .alert-fade-appear-start {
      opacity: 0;
      transform: translateY(-100%);

      @media (min-width: 640px) {
        transform: translateX(100%);
      }
    }

    .alert-fade-appear-active {
      opacity: 1;
      transform: translateY(0);

      @media (min-width: 640px) {
        transform: translateX(0);
      }
    }
  }
}
