.container {
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
  background-color: #fff7ed;
  padding-top: 60px;
  justify-content: center;
}

.contentContainer {
  width: 480px;

  :global(.ant-form-item-label) {
    padding-bottom: 4px !important;
    label {
      font-size: 16px;
    }
  }
}

.title {
  width: 100%;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  font-family: 'StabilGrotesk-Bold2';
  color: #292e62;
  margin-bottom: 8px;
  display: inline-block;
  text-align: center;
}

.description {
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #727272;
  display: inline-block;
  margin-bottom: 36px;
}

.hightlight {
  font-weight: normal;
  font-style: italic;
  font-family: 'Tiempos Headline', sans-serif;
}

.controls {
  margin-top: 50px;
  display: flex;
  align-items: center;
  column-gap: 30px;
  & > button {
    flex: 1;
  }
}
