.container {
  padding-bottom: 0;
  max-width: 375px;
  & > div:nth-of-type(2) {
    background-color: #fff7ed;
    border-radius: 16px;
    padding: 0 24px;
    padding-top: 48px;
    padding-bottom: 32px;
    & > div {
      padding: 0;
    }
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //   background-color: red;
}

.topIconContainer {
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.title {
  width: 100%;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  font-family: 'StabilGrotesk-Bold2';
  color: #292e62;
  margin-bottom: 32px;
  text-align: center;
  display: inline-block;
}

.hightlight {
  font-weight: normal;
  font-style: italic;
  font-family: 'Tiempos Headline', sans-serif;
}

.pinCodeContainer {
  & > div:first-of-type {
    width: 216px;
  }

  display: flex;
  column-gap: 16px;
  & > div > div input {
    height: 48px !important;
    background-color: #fff !important;
  }

  margin-bottom: 32px;
}

.pinCodeVisibilityToggle {
  height: 48px;
  svg {
    width: 24px;
    height: 24px;
    fill: rgba(0, 0, 0, 0.45);
  }
}

.loginIfForgotPin {
  cursor: pointer;
  font-family: 'StabilGrotesk-Bold2';
  color: #292e62;
  text-decoration: underline;
  font-size: 16px;
}
