$tooltip-arrow-width: 28px;
$tooltip-arrow-height: 12px;
$tooltip-distance: $tooltip-arrow-height + 2;
$color-soft-peach: #fff7ed;
$border-radius-base: 8px;
$space-base: 1rem; // 16px

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;

  &:global.tooltip {
    &-hidden {
      display: none;
    }

    &-placement-top,
    &-placement-topLeft,
    &-placement-topRight {
      padding: $tooltip-arrow-height 0 $tooltip-distance 0;
    }
    &-placement-right,
    &-placement-rightTop,
    &-placement-rightBottom {
      padding: 0 $tooltip-arrow-height 0 $tooltip-distance;
    }
    &-placement-bottom,
    &-placement-bottomLeft,
    &-placement-bottomRight {
      padding: $tooltip-distance 0 $tooltip-arrow-height 0;
    }
    &-placement-left,
    &-placement-leftTop,
    &-placement-leftBottom {
      padding: 0 $tooltip-distance 0 $tooltip-arrow-height;
    }

    .tooltip-content {
      position: relative;
      margin-top: -2px;
    }

    .tooltip-inner {
      text-align: left;
      text-decoration: none;
      background-color: $color-soft-peach;
      border-radius: $border-radius-base;
      padding: $space-base;
      min-height: 34px;
      box-shadow: 0px 36px 76px rgba(0, 0, 0, 0.12),
        0px 8.04px 16.98px rgba(0, 0, 0, 0.0715),
        0px 2.39px 5.05px rgba(0, 0, 0, 0.0485);
    }

    .tooltip-arrow {
      position: absolute;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      z-index: 1;
    }

    &-placement-top .tooltip-arrow,
    &-placement-topLeft .tooltip-arrow,
    &-placement-topRight .tooltip-arrow {
      bottom: -$tooltip-arrow-height;
      margin-left: -$tooltip-arrow-height;
      border-width: $tooltip-arrow-height $tooltip-arrow-width/2 0
        $tooltip-arrow-width/2;
      border-top-color: $color-soft-peach;
    }

    &-placement-top .tooltip-arrow {
      left: 50%;
    }

    &-placement-topLeft .tooltip-arrow {
      left: 15%;
    }

    &-placement-topRight .tooltip-arrow {
      right: 15%;
    }

    &-placement-right .tooltip-arrow,
    &-placement-rightTop .tooltip-arrow,
    &-placement-rightBottom .tooltip-arrow {
      left: -$tooltip-arrow-height;
      margin-top: -$tooltip-arrow-height;
      border-width: $tooltip-arrow-width $tooltip-arrow-width
        $tooltip-arrow-width 0;

      border-width: $tooltip-arrow-width/2 $tooltip-arrow-height
        $tooltip-arrow-width/2 0;
      border-right-color: $color-soft-peach;
    }

    &-placement-right .tooltip-arrow {
      top: 50%;
    }

    &-placement-rightTop .tooltip-arrow {
      top: 15%;
      margin-top: 0;
    }

    &-placement-rightBottom .tooltip-arrow {
      bottom: 15%;
    }

    &-placement-left .tooltip-arrow,
    &-placement-leftTop .tooltip-arrow,
    &-placement-leftBottom .tooltip-arrow {
      right: -$tooltip-arrow-height;
      margin-top: -$tooltip-arrow-height;
      border-width: $tooltip-arrow-width/2 0 $tooltip-arrow-height
        $tooltip-arrow-width/2;
      border-left-color: $color-soft-peach;
    }

    &-placement-left .tooltip-arrow {
      top: 50%;
    }

    &-placement-leftTop .tooltip-arrow {
      top: 15%;
      margin-top: 0;
    }

    &-placement-leftBottom .tooltip-arrow {
      bottom: 15%;
    }

    &-placement-bottom .tooltip-arrow,
    &-placement-bottomLeft .tooltip-arrow,
    &-placement-bottomRight .tooltip-arrow {
      top: -$tooltip-arrow-height;
      margin-left: -$tooltip-arrow-width/2;
      border-width: 0 $tooltip-arrow-width/2 $tooltip-arrow-height
        $tooltip-arrow-width/2;
      border-bottom-color: $color-soft-peach;
    }

    &-placement-bottom .tooltip-arrow {
      left: 50%;
    }

    &-placement-bottomLeft .tooltip-arrow {
      left: 15%;
    }

    &-placement-bottomRight .tooltip-arrow {
      right: 15%;
    }
  }
}
